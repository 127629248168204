import React from 'react';
import Helmet from 'react-helmet';
import { Header, Dropdown, Button } from 'semantic-ui-react';
import Masonry from 'react-masonry-component';

import Layout from '../components/Layout';
import GalleryImage from '../components/GalleryImage';
import '../style/gallery.css';

const artTypes = [
  "all",
  "metal",
  "statuesque collection",
  "war of art collection",
  "real men wear pink collection",
  "nature",
  "cityscape",
  "commission",
  "portraits",
  "crayon based",
  "laser cuts",
  "desks",
  "other"
];

const filterOptions = artTypes.map(filterType => ({
  key: filterType,
  value: filterType,
  text: filterType.toUpperCase()
}));

const statuses = [
  'all',
  'sold',
  'available'
];

const statusFilterOptions = statuses.map(statusFilterType => ({
  key: statusFilterType,
  value: statusFilterType,
  text: statusFilterType.toUpperCase()
}));

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filterChoice: 'all',
      statusFilterChoice: 'all',
      initialGalleryImages: [],
      currentGalleryImages: [],
      toShowIndex: 20,
      carouselIndex: 0,
    };

    this.onFilterChange = this.onFilterChange.bind(this);
    this.onStatusFilterChange = this.onStatusFilterChange.bind(this);
    this.setFilteredResults = this.setFilteredResults.bind(this);
    this.onSeeMore = this.onSeeMore.bind(this);
    this.toggleCarousel = this.toggleCarousel.bind(this);
    this.setCarouselIndex = this.setCarouselIndex.bind(this);
  }

  componentDidMount() {
    const { edges } = this.props.data.allAirtable;
    const galleryImages = edges.map((edge) => edge.node.data);

    this.setState({ currentGalleryImages: galleryImages, initialGalleryImages: galleryImages })
  }

  toggleCarousel(direction) {
    const { carouselIndex } = this.state;

    if (direction === 'left') {
      this.setState({ carouselIndex: carouselIndex - 1 })
    } else if (direction === 'right') {
      this.setState({ carouselIndex: carouselIndex + 1 })
    }
  }

  setCarouselIndex(i) {
    this.setState({ carouselIndex: i })
  }

  onSeeMore() {
    const { initialGalleryImages, toShowIndex } = this.state;


    if (initialGalleryImages.length - toShowIndex < 20) {
      this.setState({ toShowIndex: initialGalleryImages.length, carouselIndex: 0 })
    } else {
      const newIndex = toShowIndex + 20 > initialGalleryImages.length ? initialGalleryImages.length : toShowIndex + 20;
      this.setState({ toShowIndex: newIndex, carouselIndex: 0 })
    }
  }

  onFilterChange(e, { value }) {
    const { statusFilterChoice } = this.state;

    this.setState({ filterChoice: value });

    this.setFilteredResults(value, statusFilterChoice);
  }

  onStatusFilterChange(e, { value }) {
    const { filterChoice } = this.state;

    this.setState({ statusFilterChoice: value });

    this.setFilteredResults(filterChoice, value);
  }

  setFilteredResults(filterChoice, statusFilterChoice) {
    const { initialGalleryImages } = this.state;
    console.log(filterChoice, statusFilterChoice)

    let filteredGalleryImages = initialGalleryImages;

    if (filterChoice !== 'all') {
      filteredGalleryImages = filteredGalleryImages.filter(({ Type }) => {
        const standardizedTypes = (Type || []).map(aType => aType.toLowerCase());

        return standardizedTypes.includes(filterChoice.toLowerCase())
      });
    }

    if (statusFilterChoice !== 'all') {
      filteredGalleryImages = filteredGalleryImages.filter(({ Original_Status }) => {
        if (!Original_Status) {
          return false;
        }
        return Original_Status.toLowerCase() === statusFilterChoice.toLowerCase();
      });
    }

    this.setState({
      currentGalleryImages: filteredGalleryImages,
      carouselIndex: 0,
      toShowIndex: filteredGalleryImages.length
    })
  }

  render() {
    const { filterChoice, statusFilterChoice, currentGalleryImages, initialGalleryImages, toShowIndex, carouselIndex } = this.state;
    return (
      <Layout>
        <Helmet
          title={`Award-winning Contemporary Boston Artist`}
          meta={[
            { name: 'description', content: 'Ari is an award-winning Boston artist whose work can be found in commercial and public spaces throughout Boston, NYC and beyond, as well as in private collections across the globe.' },
          ]}
        />
        <div className="filters">
          <Header className="gallery-filter-header" as="h4">Type:</Header>
          <Dropdown
            className="gallery-filter-dropdown"
            placeholder="TYPE"
            onChange={this.onFilterChange}
            value={filterChoice}
            text={filterChoice.toUpperCase()}
            selection
            options={filterOptions}
          />
          <Header className="gallery-filter-header" as="h4">Availability:</Header>
          <Dropdown
            className="gallery-filter-dropdown"
            placeholder="STATUS"
            onChange={this.onStatusFilterChange}
            value={statusFilterChoice}
            text={statusFilterChoice.toUpperCase()}
            selection
            options={statusFilterOptions}
          />
        </div>
        <Masonry
          className={'my-gallery-class'}
          elementType="div"
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {currentGalleryImages.length > 0
            ? currentGalleryImages.slice(0, toShowIndex).map((image, index) => (
              <GalleryImage
                key={image.Title}
                carouselIndex={carouselIndex}
                onToggle={this.toggleCarousel}
                image={image}
                initialIndex={index}
                toShow={toShowIndex}
                currentGalleryImages={currentGalleryImages}
                setCarouselIndex={this.setCarouselIndex}
              />
            ))
            : <h4>No Results</h4>
          }
        </Masonry>
        {initialGalleryImages.length !== toShowIndex && filterChoice === 'all'
          && (
            <div style={{ textAlign: 'center', marginTop: '1em' }}>
              <Button className="see-more-gallery-btn" onClick={this.onSeeMore}>See More</Button>
            </div>
          )
        }
      </Layout>
    );
  }
};

export const query = graphql`
  query {
    allAirtable(filter: {table: {eq: "Artwork"}}, sort: {fields: data___Ordinal}) {
      edges {
        node {
          data {
            Title
            Image {
              url
            }
            ImageURL
            Type
            Size
            Description
            Original_Status
            Print_Sizes
            Print_Prices
          }
        }
      }
    }
  }
`;

