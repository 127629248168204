import React from 'react';
import { navigate } from 'gatsby';
import { Modal, Icon, Grid, Button } from 'semantic-ui-react';
import Magnifier from "react-magnifier";

import { toHyphenCase } from '../utils/comm';

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
  }

  onClose() {
    const { setCarouselIndex } = this.props;
    setCarouselIndex(0);
  }

  onOpen() {
    const { setCarouselIndex, initialIndex } = this.props;
    setCarouselIndex(initialIndex);
  }

  render() {
    const { currentGalleryImages, image, carouselIndex, onToggle, toShow } = this.props;
    const currentModalImage = currentGalleryImages[carouselIndex];
    const carouselImage = currentModalImage;

    return (
      <div className={`gallery-image-container`}>
        <Modal
          onOpen={this.onOpen}
          onClose={this.onClose}
          dimmer="inverted"
          className="gallery-overlay-container"
          trigger={<img src={image.ImageURL} className="gallery-image" alt={image.Title} />}
        >
          <Modal.Content>
            <Grid verticalAlign="middle" stackable>
              <Grid.Column textAlign="left" width={10} className="no-mobile-padding">
                <h2>{carouselImage.Title}</h2>
              </Grid.Column>
              <Grid.Column textAlign="right" width={6} className="no-mobile-padding">
              <span className="gallery-overlay-cta" onClick={() => navigate(`/shop/${toHyphenCase(carouselImage.Title)}`)}>
                Details
                <Icon name="long arrow alternate right" />
              </span>
              </Grid.Column>
            </Grid>
            <Magnifier src={carouselImage.ImageURL} height="100%" mgShape="square" zoomFactor={1.25} mgWidth={400} mgHeight={400} />
            <Button
              className="modal-toggle modal-toggle-left"
              disabled={carouselIndex === 0}
              onClick={() => onToggle("left")}
            >
              <i class="arrow-left" />
            </Button>
            <Button
              className="modal-toggle modal-toggle-right"
              disabled={carouselIndex === toShow - 1}
              onClick={() => onToggle("right")}
            >
              <i class="arrow-right" />
            </Button>
            {/*<div className="gallery-overlay-content">*/}
            {/*<h2>{galleryImage.Title}</h2>*/}
            {/*<p>*/}
            {/*"{galleryImage.Size}" <br />*/}
            {/*Original: {galleryImage.Original_Status === "available" ? "AVAILABLE" : "SOLD"}*/}
            {/*</p>*/}
            {/*{galleryImage.Print_Sizes.length > 0 && <div className="gallery-overlay-cta">Prints Available</div>}*/}
            {/*<div className="gallery-overlay-cta" onClick={() => navigate(`/shop/${toHyphenCase(galleryImage.Title)}`)}>*/}
            {/*Making Of {galleryImage.Title}*/}
            {/*<Icon name="long arrow alternate right" />*/}
            {/*</div>*/}
            {/*</div>*/}
          </Modal.Content>
        </Modal>
      </div>
    )
  }
};